#About {
    /* background-image: url("../../../public/dew-drop-3.jpg"); */
    /* background-image: url("../../../public/402526.jpg"); */
    /* background-image: url("../../../public/peakpx.jpg"); */
    /* height: 800px; */
    /* width: 200vh;
    height: 100vh; */
    
    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#About h1 {
    font-family: 'Dancing Script', cursive;
    font-size: 100px;
    color: white;
}

/* #About h3 {
    font-family: 'Cormorant Upright', serif;
    font-size: 50px;
    color: black;
} */

#description {
    padding: 20px;
}